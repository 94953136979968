import { Config } from '@backstage/config';
import { createApiRef, IdentityApi} from '@backstage/core-plugin-api';
import {BusinessUnit, ChargebacksPayload, ICostEstimateReq, ICostEstimateRes, ICostLocationsRes} from 'tmna-types';
import {stringifyQueryString} from 'tmna-utils';
//import { isArray } from 'lodash';
import axios from 'axios';

export const costCenterApiRef = createApiRef<ICostCenterApi>({
    id: 'plugin.cost-center',
});

type CostCenterApiOptions = {
    configApi: Config;
    identityApi: IdentityApi;
}

type ChargebacksByCostCenterRequestParams = {
    costCenterId: string;
    wbsId: string;
    startDate: string;
    endDate: string;
}

type ChargebacksByBURequestParams = {
    l2BusinessUnit: string;
    l3BusinessUnits: string[];
    startDate: string;
    endDate: string;
}

type ChargebacksByAccountIdRequestParams = {
    accountIds: string[];
    accountName: string;
    accountOwner: string;
    startDate: string;
    endDate: string;
}

type ChargebacksDetailByAccountIdsRequestParams = {
    accountIds: string[];
    startDate: string;
    endDate: string;
}

type ChargebacksForBurnRateRequestParams = {
    accountIds: string[];
    //businessUnitsL2: string[];
    //businessUnitsL3: string[];
}

type BreakGlassRequestParams = {
    accountId: string;
    serviceNowTask: string;
    businessJustification: string;
    sessionTime: string;
    requestedBy: {
        name: string;
        email: string;
    }
  }
// type InsightsHorizonForBurnRateRequestParams = {
//     accountIds: string[];
// }

interface ICostCenterApi {
    fetchChargebacksByCostCenter(params: ChargebacksByCostCenterRequestParams): Promise<ChargebacksPayload>;
    fetchChargebacksByBusinessUnits(params: ChargebacksByBURequestParams): Promise<ChargebacksPayload>;
    fetchChargebacksByAccounts(params: ChargebacksByAccountIdRequestParams): Promise<ChargebacksPayload>;
    fetchChargebacksDetailByAccountIds(params: ChargebacksDetailByAccountIdsRequestParams): Promise<ChargebacksPayload>;
    fetchChargeBackForBurnRateByAccount(params: ChargebacksForBurnRateRequestParams): Promise<any>;
    fetchBusinessUnitsL2(idtoken:any): Promise<BusinessUnit[]>;
    fetchCostLocations:()=>Promise<ICostLocationsRes[]>;
    fetchCostEstimate(id:string,instance:ICostEstimateReq): Promise<ICostEstimateRes>;
    fetchAllCamAccounts(idtoken:any):Promise<any>;
    postBreakGlassRequest(params: BreakGlassRequestParams, access_token: string): Promise<any>;
    fetchBusinessUnitsL3(businessUnitL2: string): Promise<BusinessUnit[]>;
    fetchAllBusinessUnitsL3(businessUnitsL2: string[],signal:AbortSignal,idtoken:any): Promise<any>;
    fetchScoreCardDetailsbyL2(l2Units: string[]): Promise<any>;
    fetchScoreCardDetailsbyL3(l3Units: string[]): Promise<any>;
    fetchScoreCardDetailsbyAccountId(accountIds: string[]): Promise<any>;
    fetchCloudAccountMasterDetails(accountId: string, idtoken:any): Promise<any>;
    fetchCamAccountIds(filterValues: any, idtoken:any): Promise<any[]>;
    //fetchChargeBackForBurnRateByAccount(params: InsightsHorizonForBurnRateRequestParams): Promise<any>;
    fetchCloudSpendGraphDetailsbyL2(l2Units: string[]): Promise<any>;
    fetchCloudSpendGraphDetailsbyL3(l3Units: string[]): Promise<any>;
    fetchCloudSpendGraphDetailsbyAccountId(accountIds: string[]): Promise<any>;
    fetchAccountIdsByL3(l3Units: string[], idtoken:any,signal:AbortSignal): Promise<any>;
    fetchAccountIdsByL2(l2Units: string[], idtoken:any): Promise<any>;
    fetchBudgetOverrunDetailsbyL2(l2Units: string[]): Promise<any>;
    fetchBudgetOverrunDetailsbyL3(l3Units: string[]): Promise<any>;
    fetchBudgetOverrunDetailsbyAccountId(accountIds: string[]): Promise<any>;
    fetchAccountDetailById(accountId:string, idtoken:any):Promise<any>;
    fetchBreakGlassContactsById(accountId:string, idtoken:any):Promise<any>;
    fetchAuditHistoryById(accountId:string, idtoken:any):Promise<any>;
    fetchUserRole(idtoken:any, accountId?:string):Promise<any>;
    //CDX5229
    manageCloudAccountMaster(params:any, idtoken:any, accessTokenValue:any, postBody:any):Promise<any>;
    //createCloudAccountMaster(params:any, idtoken:any, postBody:any):Promise<any>;
    fetchContacts(accessToken:any, term:string):Promise<any>;
}

export class CostCenterApi implements ICostCenterApi {
    private configApi: Config;
    private identityApi: IdentityApi;
    constructor(options: CostCenterApiOptions) {
        this.configApi = options.configApi;
        this.identityApi = options.identityApi;
    }
    
    public async fetchChargebacksByCostCenter(params: ChargebacksByCostCenterRequestParams) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/by/cost-center`);
        const token = await this.identityApi.getCredentials();

        url.search = stringifyQueryString(params);

        return fetch(
            url.toString(),
        {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        ).then(d => d.json());
    }

    public async postBreakGlassRequest(params: BreakGlassRequestParams, access_token:string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/postBreakGlassRequest`);
        const token = await this.identityApi.getCredentials();
        const paramsWithToken = {
            ...params,
            access_token: access_token
        };
        const response = await fetch(
            url.toString(),
            {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token.token}` },
                body: JSON.stringify(paramsWithToken),
            });

        return response.json();
    }

    // public async fetchBusinessUnitsL2() {
    //     const backendUrl = this.configApi.getString('backend.baseUrl');
    //     const url = new URL(`${backendUrl}/api/chargebacks/business-units/l2`);
    //     const token = await this.identityApi.getCredentials();

    //     return fetch(
    //         url.toString(),
    //         {
    //             credentials: 'include',
    //             headers: { Authorization: `Bearer ${token.token}` }
    //         },
    //     )
    //         .then(d => d.json())
    //         .then(d => {                
    //             return d;
    //         })
    // }
    
    public async fetchCostLocations() {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/cost-locations`);

        try {

        const token = await this.identityApi.getCredentials();

        return fetch(url.toString(), {
          credentials: 'include',
          headers: { Authorization: `Bearer ${token.token}` },
        })
          .then(d => d.json())
          .then(data =>
            data?.error ? Promise.reject(data) : Promise.resolve(data),
          );
        } catch(e){
            return Promise.reject(e)
        }
      }

    public async fetchCostEstimate(id:string, instance:ICostEstimateReq) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(
          `${backendUrl}/api/chargebacks/${id}/cost-estimate`,
        );
        try {
            
        const token = await this.identityApi.getCredentials();

        return fetch(url.toString(), {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.token}`,
          },
          body: JSON.stringify(instance),
        })
          .then(d => d.json())
          .then(data =>
            data?.error ? Promise.reject(data) : Promise.resolve(data),
          );
        } catch(e){
            return Promise.reject(e)
        }
      }

    public async fetchAllCamAccounts(idtoken:any): Promise<any> {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/all-cam-accounts`);
        const token = await this.identityApi.getCredentials();        
        
        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}`, token:idtoken }
            },
        ).then(d => d.json());
    }
    public async fetchCamAccountIds(filterValues: any, idtoken:any) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url:any = new URL(`${backendUrl}/api/chargebacks/account-ids/cam`);
        const token = await this.identityApi.getCredentials();
        //url.search = stringifyQueryString(filterValues);
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token.token}`, token:idtoken }),
            },
            body: JSON.stringify({ accountnumber: filterValues?.accountnumber, appSerId:filterValues?.appSerId,bul2: filterValues?.bul2, bul3: filterValues?.bul3 , ccId: filterValues?.ccId, glId: filterValues?.glId,wbsId: filterValues?.wbsId,contactname:filterValues?.userName,contactemail:filterValues?.emailID,csp:filterValues?.csp,status:filterValues?.status,typeOfDeployment:filterValues?.typeOfDeployment,typeOfAccount:filterValues?.typeOfAccount}),
        });
        console.log("response", response)
        return await response.json();
    }

    public async fetchBusinessUnitsL3(businessUnitL2: string) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/business-units/l3`);
        const token = await this.identityApi.getCredentials();

        url.search = stringifyQueryString({business_unit_l2: businessUnitL2});

        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        )
            .then(d => d.json())
            .then(d => { return d})
    }

    

    // public async fetchStaticBusinessUnitsL2() {
    //     const backendUrl = this.configApi.getString('backend.baseUrl');
    //     const url = new URL(`${backendUrl}/api/chargebacks/business-units-static/l2`);
    //     const token = await this.identityApi.getCredentials();

    //     return fetch(
    //         url.toString(),
    //         {
    //             credentials: 'include',
    //             headers: { Authorization: `Bearer ${token}` }
    //         },
    //     )
    //         .then(d => d.json())
    //         .then(d => d.data)
    // }

    // public async fetchStaticBusinessUnitsL3(businessUnitL2s: string[]) {
    //     const backendUrl = this.configApi.getString('backend.baseUrl');
    //     const url = new URL(`${backendUrl}/api/chargebacks/business-units-static/l3`);
    //     const token = await this.identityApi.getCredentials();

    //     url.search = stringifyQueryString({business_unit_l2: businessUnitL2s});

    //     return fetch(
    //         url.toString(),
    //         {
    //             credentials: 'include',
    //             headers: { Authorization: `Bearer ${token}` }
    //         },
    //     )
    //         .then(d => d.json())
    //         .then(d => d.data)
    // }

    public async fetchChargebacksByBusinessUnits(params: ChargebacksByBURequestParams) {
        console.log(JSON.stringify(params));
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/by/business-units`);
        const token = await this.identityApi.getCredentials();

        url.search = stringifyQueryString(params);

        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        ).then(d => d.json());
    }

    public async fetchChargebacksByAccounts(params: ChargebacksByAccountIdRequestParams) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/by/accounts`);
        const token = await this.identityApi.getCredentials();
        url.search = stringifyQueryString(params);

        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        ).then(d => d.json());
    }

    public async fetchChargebacksDetailByAccountIds(params: ChargebacksDetailByAccountIdsRequestParams) {
        // const backendUrl = this.configApi.getString('backend.baseUrl');
        // const url = new URL(`${backendUrl}/api/chargebacks/by/chargebackDetail`);
        // const token = await this.identityApi.getCredentials();
        // url.search = stringifyQueryString(params);

        // return fetch(
        //     url.toString(),
        //     {
        //         credentials: 'include',
        //         headers: { Authorization: `Bearer ${token.token}` }
        //     },
        // ).then(d => d.json());


        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url:any = new URL(`${backendUrl}/api/chargebacks/by/chargebackDetail`);
        const token = await this.identityApi.getCredentials();
    
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token.token}` }),
          },
          body: JSON.stringify({ accountIds: params?.accountIds, startDate:params?.startDate,endDate: params?.endDate }),
        });
        console.log("response", response)
        return await response.json();
    }

    public async fetchChargeBackForBurnRateByAccount(params: ChargebacksForBurnRateRequestParams) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/by/accounts/burnrateData`);
        const token = await this.identityApi.getCredentials();

        url.search = stringifyQueryString(params);

        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        ).then(d => d.json());
    }

    // public async fetchAllBusinessUnitsL3(businessUnitsL2: string[]) {
    //     const backendUrl = this.configApi.getString('backend.baseUrl');
    //     const url = new URL(`${backendUrl}/api/chargebacks/business-units-multiple/l3`);
    //     const token = await this.identityApi.getCredentials();        
    //     url.search = stringifyQueryString({business_unit_l2s: businessUnitsL2});

    //     return fetch(
    //         url.toString(),
    //         {
    //             credentials: 'include',
    //             headers: { Authorization: `Bearer ${token.token}` }
    //         },
    //     )
    //         .then(d => d.json())
    //         .then(d => { return d})
    // }
    

    public async fetchScoreCardDetailsbyL2(l2Units: string[]) {        
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/scoreCardDetails-by-L2`);
        const token = await this.identityApi.getCredentials();       
        url.search = stringifyQueryString({bu_l2_array:l2Units});       
        
        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        ).then(d => d.json());
    }


    public async fetchScoreCardDetailsbyL3(l3Units: string[]) {        
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/scoreCardDetails-by-L3`);
        const token = await this.identityApi.getCredentials();                  
        
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              ...(token && { Authorization: `Bearer ${token.token}` }),
            },
            body: JSON.stringify({bu_l3_array:l3Units}),
          });
          return await response.json();
    }

    public async fetchScoreCardDetailsbyAccountId(accountIds: string[]) {        
        // const backendUrl = this.configApi.getString('backend.baseUrl');
        // const url = new URL(`${backendUrl}/api/chargebacks/scoreCardDetails-by-accountId`);
        // const token = await this.identityApi.getCredentials();        
        // url.search = stringifyQueryString({acc_id_array:accountIds});       
        
        // return fetch(
        //     url.toString(),
        //     {
        //         credentials: 'include',
        //         headers: { Authorization: `Bearer ${token.token}` }
        //     },
        // ).then(d => d.json());

        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url: any = new URL(`${backendUrl}/api/chargebacks/scoreCardDetails-by-accountId`);
        const token = await this.identityApi.getCredentials();        
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              ...(token && { Authorization: `Bearer ${token.token}` }),
            },
            body: JSON.stringify({ acc_id_array: accountIds }),
          });
          return await response.json();
    }

    public async fetchCloudAccountMasterDetails(accountId: string, idtoken:any) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/cloud-account-master`);
        let reqURL = url.toString()
        const token = await this.identityApi.getCredentials();        
        url.search = stringifyQueryString(accountId);
        const params = new URLSearchParams
        if (accountId) {
            params.set('account_id', accountId.toString());
        } else {
            throw new Error("Please pass the search params");
        }

        if (params.toString()) {
            reqURL += '?' + params      
          }

        return fetch(
            reqURL.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}`, token:idtoken }
            },
        ).then(d => d.json());
    }



    public async fetchCloudSpendGraphDetailsbyL2(l2Units: string[]) {        
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/cloudSpendGraph-by-L2`);
        const token = await this.identityApi.getCredentials();       
        url.search = stringifyQueryString({bu_l2_array:l2Units});       
        
        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        ).then(d => d.json());
    }


    public async fetchCloudSpendGraphDetailsbyL3(l3Units: string[]) {        
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/cloudSpendGraph-by-L3`);
        const token = await this.identityApi.getCredentials();
        
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              ...(token && { Authorization: `Bearer ${token.token}` }),
            },
            body: JSON.stringify({bu_l3_array:l3Units}),
          });
          return await response.json();

    }


    // public async fetchCloudSpendGraphDetailsbyAccountId(accountIds: string[]) {        
    //     const backendUrl = this.configApi.getString('backend.baseUrl');
    //     const url = new URL(`${backendUrl}/api/chargebacks/cloudSpendGraph-by-accountId`);
    //     const token = await this.identityApi.getCredentials();
    //     url.search = stringifyQueryString({acc_id_array:accountIds});       
        
    //     return fetch( 
    //         url.toString(),
    //         {
    //             credentials: 'include',
    //             headers: { Authorization: `Bearer ${token.token}` }
    //         },
    //     ).then(d => d.json()).catch(error=>
    //         {
    //             console.log(error)
    //         });
    //     ;
    // }
    public async fetchCloudSpendGraphDetailsbyAccountId(accountIds: string[]) { 
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url:any = new URL(`${backendUrl}/api/chargebacks/cloudSpendGraph-by-accountId`);
        const token = await this.identityApi.getCredentials();
    
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token.token}` }),
          },
          body: JSON.stringify({ accountIds: accountIds }),
        });
        return await response.json();
      }

    // public splitArray = <T>(arr: T[], size: number): T[][] =>
    //     arr.length > size
    //       ? [arr.slice(0, size), ...this.splitArray(arr.slice(size), size)]
    //       : [arr];
    
    // public async fetchAccountIdsByL3(bu_l3: string[], idtoken: any) {
    //     const splittedBul3s = this.splitArray(bu_l3, 25);
    
    //     const promiseReq = splittedBul3s.map(bul3Batch =>
    //       this.fetchAccountIdsByL3Fn(bul3Batch, idtoken),
    //     );
    
    //     return Promise.all(promiseReq).then(res => res.flat());
    //   }

    //   public async fetchAccountIdsByL3Fn(bu_l3: string[], idtoken: any) {
    //     const backendUrl = this.configApi.getString('backend.baseUrl');
    //     const url = new URL(`${backendUrl}/api/chargebacks/accountIds-by-L3`);
    //     const token = await this.identityApi.getCredentials();
    //     url.search = stringifyQueryString({ bu_l3_array: bu_l3 });
    
    //     return fetch(url.toString(), {
    //       credentials: 'include',
    //       headers: { Authorization: `Bearer ${token.token}`, token: idtoken },
    //     })
    //       .then(d => d.json())
    //       .then(data =>
    //         !isArray(data) || data?.hasOwnProperty('message') ? [] : data,
    //       );
    //   }


      public async fetchAccountIdsByL2(bu_l2: string[], idtoken:any) {        
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/accountIds-by-L2`);
        const token = await this.identityApi.getCredentials();        
        url.search = stringifyQueryString({bu_l2_array:bu_l2});       
        
        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` , token:idtoken }
            },
        ).then(d => d.json());
    }

    public async fetchBudgetOverrunDetailsbyL2(l2Units: string[]) {        
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/budgetOverrunDetails-by-L2`);
        const token = await this.identityApi.getCredentials();       
        url.search = stringifyQueryString({bu_l2_array:l2Units});       
        
        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        ).then(d => d.json());
    }

    public async fetchBudgetOverrunDetailsbyL3(l3Units: string[]) {        
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/budgetOverrunDetails-by-L3`);
        const token = await this.identityApi.getCredentials();
                
        url.search = stringifyQueryString({bu_l3_array:l3Units});        
        
        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}` }
            },
        ).then(d => d.json());
    }

    public async fetchBudgetOverrunDetailsbyAccountId(accountIds: string[]) {        
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url: any = new URL(`${backendUrl}/api/chargebacks/budgetOverrunDetails-by-accountId`);
        const token = await this.identityApi.getCredentials();        
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              ...(token && { Authorization: `Bearer ${token.token}` }),
            },
            body: JSON.stringify({ accountIds: accountIds }),
          });
          return await response.json();
    }
    public async fetchBreakGlassContactsById(accountId:string, idtoken:any) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/detailsaccountid`);
        const token = await this.identityApi.getCredentials();
        url.search = stringifyQueryString({accountId: accountId});

        const allDetails = await fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}`, token:idtoken}
            },
        )
        .then(d => d.json())
        return allDetails[0].contacts
        .filter((contact: { type: string; }) => contact.type === "Technical" || contact.type === "Owner")
        .map((contact: { name: any; }) => contact.name);
        
    }
    public async fetchAccountDetailById(accountId: string, idtoken:any){
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/detailsaccountid`);
        const token = await this.identityApi.getCredentials();
        url.search = stringifyQueryString({accountId: accountId});

        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}`, token:idtoken}
            },
        )
        .then(d => d.json())
        .then(d => { return d})

    }
    
    public async fetchAuditHistoryById(accountId: string, idtoken:any){
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/audit-history`);
        const token = await this.identityApi.getCredentials();

        url.search = stringifyQueryString({accountId: accountId});

        return fetch(
            url.toString(),
            {
                credentials: 'include',
                headers: { Authorization: `Bearer ${token.token}`, token:idtoken }
            },
        )
        .then(d => d.json())
        .then(d => { return d})

    }

    public async fetchUserRole(idtoken:any, accountId?: string){
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/userrole`);
        try {
            const token = await this.identityApi.getCredentials();
            if(accountId){
                url.search = stringifyQueryString({accountId: accountId});
            }

            return fetch(
                url.toString(),
                {
                    credentials: 'include',
                    headers: { Authorization: `Bearer ${token.token}`, token:idtoken}
                },
            )
            .then(d => d.json())
            .then(d => { return d})
            } catch(e){
                return Promise.reject(e)
            }  
    }
    
    public async manageCloudAccountMaster(params:any, idtoken:any, accessTokenValue:any, postBody:any){
      const backendUrl = this.configApi.getString('backend.baseUrl');
      const url = new URL(`${backendUrl}/api/chargebacks/manageCloudAccountMaster`);
      try {
        const token = await this.identityApi.getCredentials();
        if (params)
            url.search = stringifyQueryString({params: params});
        return fetch(
            url.toString(),
            {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token.token}`, token:idtoken, accesstoken:accessTokenValue},
                body: JSON.stringify(postBody),
            },
        )
        .then(d => d.json())
        .then(d => { return d})
        } catch(e){
            return Promise.reject(e)
        }  
    }

    // public async createCloudAccountMaster(params:any, idtoken:any, postBody:any){
    //     const backendUrl = this.configApi.getString('backend.baseUrl');
    //     const url = new URL(`${backendUrl}/api/chargebacks/createCloudAccountMaster`);
    //     try {
    //       const token = await this.identityApi.getCredentials();
    //       if (params)
    //           url.search = stringifyQueryString({params: params});
    //       return fetch(
    //           url.toString(),
    //           {
    //               method: 'POST',
    //               credentials: 'include',
    //               headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token.token}`, token:idtoken},
    //               body: JSON.stringify(postBody),
    //           },
    //       )
    //       .then(d => d.json())
    //       .then(d => { return d})
    //       } catch(e){
    //           return Promise.reject(e)
    //       }  
    // }


    public async fetchContacts(accessToken:any, term:string){
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/contacts`);
        try {
            const token = await this.identityApi.getCredentials();
            if (term !=="")
                url.search = stringifyQueryString({term: term});                   
            return fetch(
                url.toString(),
                {
                    credentials: 'include',
                    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token.token}`, token:accessToken},
                },
            )
            .then(d => d.json())
            .then(d => { return d})
        } catch(e){
            return Promise.reject(e)
        }  
      }
    
    public async fetchBusinessUnitsL2(idtoken:any) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/portfolio`);
        const token = await this.identityApi.getCredentials();
        return await axios(url.toString(), {
            headers:{
                'Content-Type': 'application/json',
                ...(token && { Authorization: `Bearer ${token.token}`, token:idtoken }), 
            }
        },).then(res => {
            return res.data;
        });
    }
    
    public async fetchAllBusinessUnitsL3(businessUnitsL2: string[],signal: AbortSignal,idtoken:any) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/program`);
        const token = await this.identityApi.getCredentials();        
        url.search = stringifyQueryString({business_unit_l2s: businessUnitsL2});
        
        return await axios(url.toString(), {
            signal,
            headers: {
                'Content-Type': 'application/json',
                ...(token && { Authorization: `Bearer ${token.token}`, token:idtoken }), 
            }
        }).then(res => {
            return res.data;
        }).catch(error => {
            console.log('error: ', error);
            console.log('isAxiosError', axios.isAxiosError(error));
            console.log('isCancel', axios.isCancel(error));
            throw error;
          });
    }
    public async fetchAccountIdsByL3(bu_l3: string[], idtoken: any,signal:AbortSignal) {
        const backendUrl = this.configApi.getString('backend.baseUrl');
        const url = new URL(`${backendUrl}/api/chargebacks/accountIds-by-L3`);
        const token = await this.identityApi.getCredentials();
        const requestParams = {
            Authorization: `Bearer ${token.token}`,
            signal,
            headers: {
                'accept': 'application/json',
                token: idtoken,
                ...(token && { Authorization: `Bearer ${token.token}` }),
            },
        }
        return await axios
            .post(url.toString(), { bu_l3_array: bu_l3 }, requestParams)
            .then(res => res.data)
            .catch(error => {
                console.log('error: ', error);
                console.log('isAxiosError', axios.isAxiosError(error));
                console.log('isCancel', axios.isCancel(error));
                throw error;
              });
    
    }
}


