import React, { ChangeEventHandler, FC, useMemo, useRef } from 'react';
import { useRouteRef,microsoftAuthApiRef, alertApiRef } from '@backstage/core-plugin-api';
import {
  useForm,
  Controller,
  ControllerRenderProps,
  Control,
} from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import { useStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Button,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Paper,
  Box,
  Chip,
  FormHelperText,
  FormControl,
  GridProps,
  TextFieldProps
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useAsyncFn, useAsync } from 'react-use';
import { PageLayout } from 'tmna-ui-kit';
import { Content } from '@backstage/core-components';
import { costCenterApiRef } from '@internal/plugin-cost-center/src/api';
import {
  useApi,
  IdentityApi,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { useLocation, useNavigate } from 'react-router-dom';
import { _values } from 'tmna-utils';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { Dialog, DialogActions,DialogContent ,Typography } from '@material-ui/core';
import { rootRouteRef } from '../../routes';
import { ErrorCompMessage } from './ErrorCompMessage';
import { creationDate } from '../AccountDetails';

type AutoCompProps = AutocompleteProps<any, true, true, false>;
type OnAutoCompChange = (
  prevValue: any[],
  selectedValue: any,
  onChange: (...event: any[]) => void,
  isRemove: boolean,
) => void;
type HandleContacts = (value: any) => Promise<void>;

//Generic contactcontroller to handle different types of contacts
const ContactController: FC<Omit<AutoCompProps, 'renderInput'> & {
  control: Control<any, any>;
  name: string;
  onAutoCompChange: OnAutoCompChange;
  handleContacts: HandleContacts;
  placeholder?: string;
  label?: string;
}> = props => {
  const {
    control,
    name,
    disabled,
    onAutoCompChange,
    handleContacts,
    placeholder,
    label,
    ...autoCompleteProps
  } = props;
  const labelCustom = label && label.substring(0, label.length-1);
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate:{ required:(value)=> { 
          const existLength = value.filter((data:any)=>data.isExist).length;
          const deletedLength = value.filter((data:any)=>data.isDeleted).length;
          const newLength = value.length - existLength
          if(newLength == 0 && (existLength == deletedLength)){
            return `${labelCustom} is required `
          }else{
            return undefined;
          }
        } }
      }}
      render={({ field: { value, onChange },fieldState: { error } }: any) => (
        <>
        <FormControl fullWidth  error={!!error}>
          <Autocomplete
            {...autoCompleteProps}
            multiple
            disableClearable
            getOptionSelected={(option, optionValue) =>
              option.displayName === optionValue.displayName &&
              !optionValue?.isDeleted
            }
            getOptionLabel={option => option.displayName}
            filterOptions={option => option}
            value={value}
            popupIcon={<SearchIcon />}
            onChange={(_e, _newValue, reason, detail) => {
              const selectedValue = detail?.option;
              const isRemove = reason === 'remove-option';
              onAutoCompChange(value, selectedValue, onChange, isRemove);
            }}
            onInputChange={(_e, v) => handleContacts(v)}
            disabled={disabled}
            renderTags={() => null} 
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={placeholder}
                label={
                  <span>
                    {label} <span style={{ color: 'red' }}>*</span>
                  </span>
                }
              />
            )}
          />
          <FormHelperText 
              children={error?.message ?? null}
            />
          </FormControl>
          <div style={{ marginTop: '10px', height: '80px', overflowY: 'auto' }}>
            <Box>
              {value.map(
                (tag: any) =>
                  !tag?.isDeleted && (
                    <Chip
                      key={tag.displayName}
                      label={tag.displayName}
                      onDelete={() =>
                        onAutoCompChange(value, tag, onChange, true)
                      }
                      disabled={disabled}
                    />
                  ),
              )}
            </Box>
          </div>
        </>
      )}
    />
  );
};
//Handle incorrect data for account status
const acountStatus = {active:"Active", deleted:"Deleted"}
const getAccountStatus = (type:string) => {
const lowerCaseValue = (type?.toLowerCase()?? null) as keyof typeof acountStatus;
 return acountStatus?.[lowerCaseValue] ?? null;
}

const CTPortfolioValue = "CONNECTED TECHNOLOGY";

//Handle incorrect data for account type
const accountTypes = {prod:"Prod", nonprod:"NonProd", sandbox:"Sandbox"}
const getAccountType = (type:string) => {
 const lowerCaseValue = (type?.toLowerCase()?? null) as keyof typeof accountTypes;
 return accountTypes?.[lowerCaseValue] ?? null;
}

//Handle incorrect data for CSP
const csps = {azure:"Azure", aws:"AWS", gcp:"GCP"}
const getCSP = (type:string) => {
 const lowerCaseValue = (type?.toLowerCase()?? null) as keyof typeof csps;
 return csps?.[lowerCaseValue] ?? null;
}

//Handle incorrect data for deployment model
const deploymentModels = {public:"Public", private:"Private"}
const getDeploymentModel = (type:string) => {
 const lowerCaseValue = (type?.toLowerCase()?? null) as keyof typeof deploymentModels;
 return deploymentModels?.[lowerCaseValue] ?? null;
}

//Handle incorrect data for root
const roots = {tmna:"TMNA", 'd&is':"D&IS", ct:"CT", tmf:"TMF"}
const getroot = (type:string) => {
 const lowerCaseValue = (type?.toLowerCase()?? null) as keyof typeof roots;
 return roots?.[lowerCaseValue] ?? null;
}

//Handle incorrect data for MFA Enabled
const mfas = {'n/a':"N/A", yes:"Yes", no:"No"}
const getMFA = (type:string) => {
 const lowerCaseValue = (type?.toLowerCase()?? null) as keyof typeof mfas;
 return mfas?.[lowerCaseValue] ?? null;
}

const NonEditableTextField: FC<{
  gridProps?: GridProps;
  control: Control;
  name: any;
  label: TextFieldProps['label'];
}> = ({ gridProps = {}, control, name, label }) => (
  <Grid item xs={4} {...gridProps}>
    <Controller
      {...{ control, name }}
      render={({ field: { value } }) => (
        <TextField {...{ label, value }} disabled />
      )}
    />
  </Grid>
);

export const CloudAccountEdit:FC<{isEdit:boolean}>= ({isEdit}) => {
  const classes = useStyles();
  const authref = useApi(microsoftAuthApiRef);
  const costCenterApi = useApi(costCenterApiRef);
  const location: any = useLocation();
  const navigate = useNavigate();
  const { state: routerState } = location;
  const [routerValues] = React.useState(routerState as any);
  const id = routerValues?.accountId;
  const [l2Values, setL2Values] = React.useState([] as string[]);
  const [l3Values, setL3Values] = React.useState([] as string[]);
  const [accessToken, setAccessToken] = React.useState('');
  const identityApi: IdentityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);
  const [disableAccountInfo, setDisableAccountInfo] = React.useState(true);
  const [disableContactDetails, setDisableContactDetails] = React.useState(
    true,
  );
  const [
    disableApplicationDetails,
    setDisableApplicationDetails,
  ] = React.useState(true);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [disableFinancialInfo, setDisableFinancialInfo] = React.useState(true);
  // const [disableApplicationName, setDisableApplicationName] = React.useState(
  //   true,
  // );
  const [open, setOpen] = React.useState(false);
  const [disableGLID, setDisableGLID] = React.useState(false); 
  const [openSave, setOpenSave] = React.useState(false);
  const [validationForEmailOnRoot, setValidationForEmailOnRoot] = React.useState(false);
  const [validationForEmailOnCSP, setValidationForEmailOnCSP] = React.useState(false);
  const [disableRootOrg, setDisableRootOrg] = React.useState(
    false,
  );
  const abortControllerRef = useRef<AbortController>(new AbortController());

  // const [reqCheckForCompanyCode, setReqCheckForCompanyCode] = React.useState(true);
  // const [reqCheckForGL, setReqCheckForGL] = React.useState(true);
  // const [reqCheckForCostCenter, setReqCheckForCostCenter] = React.useState(true);
  // const [reqCheckForWBSID, setReqCheckForWBSID] = React.useState(true);
  // const [reqCheckForMonthlySpend, setReqCheckForMonthlySpend] = React.useState(true);
  const [disableAccId, setDisableAccId] = React.useState(
    true,
  );
  const [errorCompShow, setErrorCompShow] = React.useState({isError:false,errorMsg:""});
  //const queryParams = new URLSearchParams(window.location.search)
  //const id = queryParams.get('accountId') ;

  const [contactsData, setContactsData] = React.useState({} as any);
  const [isAppIdRequired, setIsAppIdRequired] = React.useState(true);
  //To populate contacts dropdowns on page load
  const getContacts = async () => {
    try 
    {
    const tokenValue = await authref.getAccessToken();
    const contactsResponse = await costCenterApi.fetchContacts(tokenValue, '');
    setAccessToken(tokenValue as any);
    setContactsData(contactsResponse as any);
  } catch (e) {
    console.log(`error while fetching contacts ${e}`);}
  };
  React.useEffect(() => {
    getContacts();
  }, []);

  //To populate contacts dropdowns during term search
  const handleContacts = async (value: any) => {
    try 
    {
    const contactsResponse = await costCenterApi.fetchContacts(
      accessToken,
      value,
    );
    setContactsData(contactsResponse as any);
  } catch (e) {
    console.log(`error while fetching contacts ${e}`);        }
  };

  const rootLink = useRouteRef(rootRouteRef);
  const backToLink = React.useMemo(
    () => ({
      to: rootLink(),
      label: 'Back to Cloud Account Master',
    }),
    [rootLink],
  );
  //Initialize the CAM attributes
  const { handleSubmit, control, setValue , getValues, clearErrors, formState: { errors,isDirty }, trigger} = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      accId: '',
      accName: '',
      accStatus: '',
      accType: '',
      cloudServiceProvider: '',
      deploymentModel: '',
      rootOrg: '',
      rootEmailId: '',
      mfaEnabled: '',
      finSponsor: [],
      accOwner: [],
      techContact: [],
      appName: 'NULL',
      appServiceId: '',
      businessUnitsL2: '',
      businessUnitsL3: '',
      mse: 1,
      wbsId: '',
      costCenter: '',
      genLedId: '',
      compCode: '',
      notes: '',
    },
  });

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClickScroll = (id: string, yOffset = -75) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    if (id == 'headTop') {
      setTabValue(0);
    }
  };

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(12),
      marginTop: '-5px',
    },
  }))(Tooltip);

  //Populate BUL3 based on selected BUL2
  const handleBUL2Change = async (value: any) => {
    setValue('businessUnitsL2', value);
    setDisableGLID(getValues('businessUnitsL2').toUpperCase() !== CTPortfolioValue); 
    if (getValues('businessUnitsL2').toUpperCase() !== CTPortfolioValue) {
      setValue('genLedId', "6172320004")
    }
    trigger('genLedId');
    try {
    abortControllerRef.current.abort();
    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;
    const token: string = await authref.getIdToken();
    const bUl3 = await costCenterApi.fetchAllBusinessUnitsL3(value,newAbortController.signal,token);
    setL3Values(bUl3[0].l3.sort());
  } catch(e){
    console.log(`error while fetching L3 Business units ${e}`);}
  };

  const validNumber = useMemo(() => new RegExp(/^\d*\.?\d*$/), []);

  const onMonthlySpendChange = (
    event: Parameters<
      ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    >[0],
    onChange: ControllerRenderProps['onChange'],
    prevValue: string | number,
  ) => {
    const currentValue = event.target.value;

    // Remove leading zeros and zeros after decimal point
    // const replacedValue = currentValue.replace(/^(?:0+(?=[1-9])|0+(?=0$))/gm, "");
    const isValid = validNumber.test(currentValue);
    const returnValue = isValid ? currentValue : prevValue;
    onChange(returnValue);
  };

  const {
    value: _defaultLoadData = {} as any,
    loading: defaultLoadDataLoading,
  } = useAsync(async () => {
    const token: string = await authref.getIdToken();
    let defaultData:any;
    
      //get BU L2
      const bUl2 = await costCenterApi.fetchBusinessUnitsL2(token);
      setL2Values(bUl2);
      //get Account details
      if (id && isEdit) {
        defaultData = await costCenterApi.fetchAccountDetailById(id, token);

        console.log('CDX5229- #1 defaultData', defaultData);
      }
    //get user role
    const getRole = await costCenterApi.fetchUserRole(token, id);
    //Define access to different sections of the edit page based on the user role
    switch (getRole.user_role) {
      case 'Technical':
        setDisableContactDetails(false);
        setDisableApplicationDetails(false);
        setDisableFinancialInfo(false);
        businessRuleForRoot(defaultData[0]?.root_org);
        break;
      case 'Owner':
        setDisableContactDetails(false);
        setDisableApplicationDetails(false);
        setDisableFinancialInfo(false);
        businessRuleForRoot(defaultData[0]?.root_org);
        break;
      case 'Sponsor':
        setDisableContactDetails(false);
        setDisableApplicationDetails(false);
        setDisableFinancialInfo(false);
        businessRuleForRoot(defaultData[0]?.root_org);
        break;
      case 'RootAdmin':
        businessRuleForRoot(getRole.user_org); 
        isEdit && businessRuleForCSP(defaultData[0]?.csp);
        if(!isEdit && getRole.user_org)
        {
          setValue('rootOrg', getRole.user_org);
          setDisableAccId(false);          
                  
        }
        setDisableRootOrg(true);
        if (defaultData?.[0]?.root_org===getRole.user_org || !isEdit)
        {
          setDisableAccountInfo(false);
          setDisableContactDetails(false);
          setDisableApplicationDetails(false);
          setDisableFinancialInfo(false);
          //setDisableApplicationName(false);
        }
        break;
      case 'GlobalAdmin':
        setDisableAccountInfo(false);
        setDisableContactDetails(false);
        setDisableApplicationDetails(false);
        isEdit && businessRuleForRoot(defaultData[0]?.root_org);
        isEdit && businessRuleForCSP(defaultData[0]?.csp);
        setDisableFinancialInfo(false);
        //setDisableApplicationName(false);
        setDisableAccId(false);
        break;
      //finance analyst will be added once available
      default:
        setDisableAccountInfo(true);
        setDisableContactDetails(true);
        setDisableApplicationDetails(true);
        setDisableFinancialInfo(true);
        //setDisableApplicationName(true);
    }
    setValue('genLedId', '6172320004');
    !isEdit && setValue('accStatus', 'Active');
    //Populate attributes with the existing values fetched from get account call
    if (defaultData && defaultData.length > 0) {
      setValue('accId', defaultData[0]?.account_number);
      setValue('accName', defaultData[0]?.account_name);
      setValue('accStatus', getAccountStatus(defaultData[0]?.status));
      const accountType = getAccountType(defaultData[0]?.account_type);
      setIsAppIdRequired(accountType !== 'Sandbox');
      setValue('accType', accountType);
      setValue('cloudServiceProvider', getCSP(defaultData[0]?.csp));
      setValue('deploymentModel', getDeploymentModel(defaultData[0]?.deployment_model));
      //setValue('root', defaultData[0]?.root_org);
      setValue('rootOrg', getroot(defaultData[0]?.root_org));
      setValue('rootEmailId', defaultData[0]?.root_email);
      setValue('mfaEnabled', getMFA(defaultData[0]?.mfa_enabled));
      setValue('appName', defaultData[0]?.app_name === '' ? 'NULL' : defaultData[0]?.app_name);
      setValue('appServiceId', defaultData[0]?.app_id);
      setValue('businessUnitsL2', defaultData[0]?.bu_l2);
      setValue('businessUnitsL3', defaultData[0]?.bu_l3);
      setValue('mse', defaultData[0]?.monthly_estimate !== 0 ? defaultData[0]?.monthly_estimate : '');
      setValue('wbsId', defaultData[0]?.wbs_id);
      setValue('costCenter', defaultData[0]?.cost_center);
      setValue('genLedId', defaultData[0]?.gl_id);
      setValue('compCode', defaultData[0]?.company_code);
      setValue('notes', defaultData[0]?.comments);
      setValue('created', creationDate(defaultData[0]?.created));
      setValue('created_by', defaultData[0]?.created_by);
      setValue('modified', creationDate(defaultData[0]?.modified));
      handleBUL2Change(defaultData[0]?.bu_l2);
      setDisableGLID(getValues('businessUnitsL2').toUpperCase() !== CTPortfolioValue); 
      //requiredCheckForTMNA(defaultData[0]?.root_org);

      const Owner: any = defaultData[0]?.contacts
        .filter((item: any) => {
          return item.type === 'Owner';
        }).sort((a:any,b:any) => a.name.localeCompare(b.name))
        .map((data: { name: any; email: any }) => ({
          displayName: data?.name,
          mail: data?.email,
          isExist: true,
        }));
      setValue('accOwner', Owner);

      const Sponsor: any = defaultData[0]?.contacts
        .filter((item: any) => {
          return item.type === 'Sponsor';
        }).sort((a:any,b:any) => a.name.localeCompare(b.name))
        .map((data: { name: any; email: any }) => ({
          displayName: data?.name,
          mail: data?.email,
          isExist: true,
        }));

      setValue('finSponsor', Sponsor);

      const Technical: any = defaultData[0]?.contacts
        .filter((item: any) => {
          return item.type === 'Technical';
        }).sort((a:any,b:any) => a.name.localeCompare(b.name))
        .map((data: { name: any; email: any }) => ({
          displayName: data?.name,
          mail: data?.email,
          isExist: true,
        }));
      setValue('techContact', Technical);

      return { dataShow: defaultData[0] };
    }
    return { dataShow: defaultData };
  }, []);

  const onAutoCompChange = (
    prevValue: any[],
    selectedValue: any,
    onChange: (...event: any[]) => void,
    isRemove: boolean,
  ) => {
    const selectedIndex = prevValue.findIndex(
      ({ displayName }) => displayName === selectedValue.displayName,
    );
    const isInList = selectedIndex !== -1;
    const isExist = prevValue[selectedIndex]?.isExist ?? false;
    let updatedList = prevValue;

    if (!isRemove && isInList && isExist) {
      updatedList = prevValue.map((data, i) =>
        i === selectedIndex ? { ...data, isDeleted: false } : data,
      );
    } else if (!isRemove) {
      updatedList = [...prevValue, selectedValue];
    } else if (isRemove && isInList && isExist) {
      updatedList = prevValue.map((data, i) =>
        i === selectedIndex ? { ...data, isDeleted: true } : data,
      );
    } else {
      updatedList = prevValue.filter((_data, i) => !(i === selectedIndex));
    }
    onChange(updatedList);
  };
  const [{ value: _data }, onSubmitEdit] = useAsyncFn(async (value: any) => {
    const tokenValue: string = await authref.getIdToken();
    const accessTokenValue = await authref.getAccessToken();
    const userName = (await identityApi.getProfileInfo()).displayName;

    const financialSponsors = value.finSponsor.map((data: any) => ({
      email: data.mail,
      type: 'Sponsor',
      name: data.displayName,
      status: data?.isDeleted ? 'Deleted' : 'Active',
    }));

    const accountOwners = value.accOwner.map((data: any) => ({
      email: data.mail,
      type: 'Owner',
      name: data.displayName,
      status: data?.isDeleted ? 'Deleted' : 'Active',
    }));

    const technicalContacts = value.techContact.map((data: any) => ({
      email: data.mail,
      type: 'Technical',
      name: data.displayName,
      status: data?.isDeleted ? 'Deleted' : 'Active',
    }));

    let postBody = [
      {
        account_number: value.accId,
        account_name: value.accName,
        status: value.accStatus,
        account_type: value.accType,
        csp: value.cloudServiceProvider,
        deployment_model: value.deploymentModel,
        root_org: value.rootOrg,
        ...(value.rootEmailId !== '' ? { root_email: value.rootEmailId } : {}),
        mfa_enabled: value.mfaEnabled,
        bu_l2: value.businessUnitsL2,
        bu_l3: value.businessUnitsL3,
        app_name: value.appName,
        app_id: value.appServiceId,
        company_code: value.compCode,
        gl_id: value.genLedId,
        cost_center: value.costCenter,
        wbs_id: value.wbsId?value.wbsId:(value.rootOrg==='TMNA'?'000001':''),
        monthly_estimate: Number(value.mse),
        saved_by: userName,
        comments: value.notes,
        contacts: [
          ...financialSponsors,
          ...accountOwners,
          ...technicalContacts,
        ],
      },
    ];

    const params = {
      accountId: isEdit ? id : value.accId,
      rootOrg: encodeURIComponent(value.rootOrg),
      isEdit: isEdit,
    };
    console.log('CDX5229 - sending postBody on Edit', postBody);
    //CAM edit call
    setSaveLoading(true);
    try {
        let camResponse = await costCenterApi.manageCloudAccountMaster(
          params,
          tokenValue,
          accessTokenValue,
          postBody
        );
      setSaveLoading(false);
      if (camResponse?.code == 1) {
        alertApi.post({
          message: camResponse.message,
          severity: 'success',
          display: 'transient',
        });
        navigate(`/cloud-account/CloudAccountDetails?accountId=${value.accId}`);
      } else {
        const hasInactiveUser = camResponse.message.includes("Inactive user(s)");
        if (hasInactiveUser) {
          setErrorCompShow((preVal) => ({...preVal, isError: true, errorMsg: camResponse.message}));
        }else{
          alertApi.post({
            message: camResponse.message,
            severity: 'error',
            display: 'transient',
          });
        }
      }
    } catch (e) {
      alertApi.post({
        message: 'Something went wrong, please try again!',
        severity: 'error',
        display: 'transient',
      });
      setSaveLoading(false);
    }
  });
  const handleClickOpen = () => {
    if(!isDirty){
      navigate(-1);
    }else{
      setOpen(true);
    }
  };

  const handleClose = (msg:string) => {
    if(msg == "yes"){
      setOpen(false);
      navigate(-1)
    }else{
      setOpen(false);
    }
  };
  const handleCloseSave = (msg:string) => {
    if(msg == "yes"){
      setOpenSave(false);
      handleSubmit(onSubmitEdit)()
    }else{
      setOpenSave(false);
    }
  };
  const submitFunction = () =>{
    const status = getValues("accStatus");
    if(status == "Deleted"){
      setOpenSave(true);
    }else{
      handleSubmit(onSubmitEdit)()
    }
  }

  const businessRuleForRoot = (value:any) =>{
    if(value == "TMNA"){
      // setReqCheckForCompanyCode(true);
      // setReqCheckForGL(true);
      // setReqCheckForCostCenter(true);
      // setReqCheckForWBSID(true);
      // setReqCheckForMonthlySpend(true);
      // setValue('genLedId', '6172320004');
      // setDisableFinancialInfo(false);
      setValidationForEmailOnRoot(true);
    }else{
      // clearErrors('compCode');
      // clearErrors('genLedId');
      // clearErrors('costCenter');
      // clearErrors('wbsId');
      // clearErrors('mse');
      // setReqCheckForCompanyCode(false);
      // setReqCheckForGL(false);
      // setReqCheckForCostCenter(false);
      // setReqCheckForWBSID(false);
      // setReqCheckForMonthlySpend(false);
      // setDisableFinancialInfo(true);
      setValidationForEmailOnRoot(false);
    }
  }

  const businessRuleForCSP = (value:any) =>{
    if(value == "AWS"){
      setValidationForEmailOnCSP(true);
    }else{
      clearErrors('rootEmailId');
      setValidationForEmailOnCSP(false);
    }
  }
  return (
    <PageLayout
      title={`${isEdit? "Edit Cloud Account": "Create Cloud Account"}`}
      subtitle=""
      headerBackgroundImg={'/assets/service-catalog_bg.png'}
      backToLink={backToLink}
      displayButton={true}
    >
      {errorCompShow.isError && <ErrorCompMessage errorCompShow={errorCompShow} setErrorCompShow={setErrorCompShow} />}
      <form onSubmit={handleSubmit(onSubmitEdit)}>
        <Paper className={classes.root} data-testid="tabsHeader" style={{display:'flex',padding: "0px 24px",
    justifyContent: "space-between"}}>
        <div style={{width:'100%'}}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="secondary"
            centered
          >
            <Tab
              label="Account Information"
              data-testid="AccountInformationTab"
              onClick={() => handleClickScroll('AccountInformation', -125)}
            />
            <Tab
              label="Contact Details"
              data-testid="ContactDetailsTab"
              onClick={() => handleClickScroll('ContactDetails', -125)}
            />
            <Tab
              label="Application Details"
              data-testid="ApplicationDetailsTab"
              onClick={() => handleClickScroll('ApplicationDetails', -125)}
            />
            <Tab
              label="Financial Information"
              data-testid="FinancialInformationTab"
              onClick={() => handleClickScroll('FinancialInformation', -125)}
            />
            <LightTooltip title="Scroll to Top">
              <ExpandLessIcon
                style={{
                  marginTop: '15px',
                  width: '40px',
                  height: '40px',
                  color: 'red',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                }}
                data-testid="headTopTab"
                onClick={() => handleClickScroll('headTop')}
              ></ExpandLessIcon>
            </LightTooltip>
          </Tabs>
          </div>
          <div className={classes.actionBtnDiv}>
              <Button
                //onClick={() => navigate(-1)}
                onClick={handleClickOpen}
                variant="contained"
                color="secondary"
                style={{
                  color: '#000000' ,
                  boxShadow: '0 0 0 2px #000000 inset',
                  marginRight: '16px',
                  background: 'white',
                }}
              >
                Cancel
              </Button>
             
                <Dialog
                open={open}
                onClose={()=>handleClose("no")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <Typography gutterBottom>
                    You have unsaved edits. Would you like to discard changes?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>handleClose("no")} color="primary" variant="contained" style={{
                  color: '#000000' ,
                  boxShadow: '0 0 0 2px #000000 inset',
                  marginRight: '16px',
                  background: 'white',
                }}>
                    No
                  </Button>
                  <Button onClick={()=>handleClose("yes")} variant="contained" color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              
              <Button
                className={classes.saveBtn}
                variant="contained"
                color="primary"
                onClick={submitFunction}
                disabled={!isDirty || saveLoading || Object.keys(errors).length > 0 }
              >
                {saveLoading ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  'Save'
                )}
              </Button>
              <Dialog
                open={openSave}
                onClose={()=>handleCloseSave("no")}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
              >
                <DialogContent>
                  <Typography gutterBottom>
                  Please confirm that you intend to mark this cloud account as "Deleted".
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>handleCloseSave("no")} color="primary" variant="contained" style={{
                  color: '#000000' ,
                  boxShadow: '0 0 0 2px #000000 inset',
                  marginRight: '16px',
                  background: 'white',
                }}>
                    No
                  </Button>
                  <Button  variant="contained"
                   onClick={()=>handleCloseSave("yes")} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
        </Paper>
        <div id="headTop" className={classes.tabContent}>
          <Content>
            {Object.keys(errors).length > 0 &&
              <div className={classes.customAlert}><Alert severity="error">Edits made cannot be saved until the mandatory fields are completed. Please review errors highlighted in red text.</Alert>  </div>
            }
            <Card className={classes.root} style={{marginTop: "1rem"}}>
              {defaultLoadDataLoading && (
                <div className={classes.spinner}>
                  <CircularProgress color="secondary" />
                </div>
              )}
              {defaultLoadDataLoading === false && (
                <CardContent>
                  <div className={classes.header}>
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '2.5',
                      }}
                    >
                      Account Information
                    </span>
                  </div>
                  <Grid item id="AccountInformation">
                    <Divider orientation="horizontal" />
                  </Grid>
                  <Grid item xs={12} className={classes.rowMargin}>
                    <Grid container>
                      <Grid item xs={4} data-testid="accountId">
                        <Controller
                          control={control}
                          name="accId"
                          rules={{
                            required: 'Account ID is required',
                            maxLength: {
                              value: 60,
                              message: '(max length 60)',
                            },
                          }}
                          render={({
                            field: { value, onChange, ref },
                            fieldState: { error },
                          }: any) => (
                            <TextField
                              label={
                                <span>
                                  Account ID <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              inputRef={ref}
                              helperText={error?.message ?? '(max length 60)'}
                              disabled={disableAccId}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} data-testid="accountName">
                        <Controller
                          control={control}
                          name="accName"
                          rules={{
                            required: 'Account Name is required',
                            maxLength: {
                              value: 150,
                              message: '(max length 150)',
                            },
                          }}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }: any) => (
                            <TextField
                              label={
                                <span>
                                  Account Name <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message ?? '(max length 150)'}
                              disabled={disableAccountInfo}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} data-testid="accStatus">
                        <InputLabel shrink id="account-status-label">
                          Account Status <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Controller
                          control={control}
                          name="accStatus"
                          rules={{
                            required: 'Account Status is required',
                          }}
                          render={({ field: { value, onChange },fieldState: { error } }: any) => (
                            <FormControl fullWidth  error={!!error}>
                            <Select
                              fullWidth
                              className={classes.customWidth}
                              labelId="account-status-label"
                              id="account-status-select"
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              disabled={disableAccountInfo}
                            >
                              <MenuItem value="Active">Active</MenuItem>
                              <MenuItem value="Deleted">Deleted</MenuItem>
                            </Select>
                            <FormHelperText 
                                children={error?.message ?? null}
                              />
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid="accountType">
                        <InputLabel shrink id="account-type-label">
                          Account Type <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Controller
                          control={control}
                          name="accType"
                          rules={{
                            required: 'Account Type is required',
                          }}
                          render={({ field: { value, onChange },fieldState: { error } }: any) => (
                            <FormControl fullWidth  error={!!error}>
                            <Select
                              fullWidth
                              className={classes.customWidth}
                              labelId="account-type-label"
                              id="account-type-select"
                              value={value}
                              onChange={(e) => {
                                if(e.target.value === 'Sandbox')
                                 setIsAppIdRequired(false);
                                else
                                 setIsAppIdRequired(true);
                                onChange(e);
                               }}
                              variant="outlined"
                              disabled={disableAccountInfo}
                            >
                              <MenuItem value="Prod">Prod</MenuItem>
                              <MenuItem value="NonProd">NonProd</MenuItem>
                              <MenuItem value="Sandbox">Sandbox</MenuItem>
                            </Select>
                              <FormHelperText
                                children={error?.message ?? null}
                              />
                            </FormControl>
                          )}
                        />
                        
                      </Grid>

                      <Grid item xs={4} data-testid="cloudServiceProvider">
                        <InputLabel shrink id="csp-label">
                          Cloud Service Provider <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Controller
                          control={control}
                          name="cloudServiceProvider"
                          rules={{
                            required: 'CSP is required',
                          }}
                          render={({ field: { value, onChange },fieldState: { error }}: any) => (
                            <FormControl fullWidth  error={!!error}>
                            <Select
                              fullWidth
                              className={classes.customWidth}
                              labelId="csp-label"
                              id="csp-select"
                              value={value}
                              onChange={(e)=>{
                                onChange(e);
                                businessRuleForCSP(e.target.value);
                              }}
                              variant="outlined"
                              disabled={disableAccountInfo}
                            >
                              <MenuItem value="Azure">Azure</MenuItem>
                              <MenuItem value="AWS">AWS</MenuItem>
                              <MenuItem value="GCP">GCP</MenuItem>
                            </Select>
                            <FormHelperText
                                children={error?.message ?? null}
                              />
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} data-testid="deploymentModel">
                        <InputLabel shrink id="deploymentModel-label">
                          Deployment Model <span style={{ color: 'red' }}>*</span>
                          
                        </InputLabel>
                        <Controller
                          control={control}
                          name="deploymentModel"
                          rules={{
                            required: 'Deployment Model is required',
                          }}
                          render={({ field: { value, onChange },fieldState: { error } }: any) => (
                            <FormControl fullWidth  error={!!error}>
                            <Select
                              fullWidth
                              className={classes.customWidth}
                              labelId="deploymentModel-label"
                              id="deploymentModel-select"
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              disabled={disableAccountInfo}
                            >
                              <MenuItem value="Public">Public</MenuItem>
                              <MenuItem value="Private">Private</MenuItem>
                            </Select>
                            <FormHelperText
                              children={error?.message ?? null}
                            />
                          </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid="root">
                        <InputLabel shrink id="root-label">
                          Root <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Controller
                          control={control}
                          name="rootOrg"
                          rules={{
                            required: 'Root is required',
                          }}
                          render={({ field: { value, onChange },fieldState: { error } }: any) => (
                            <FormControl fullWidth  error={!!error}>
                              <Select
                                fullWidth
                                className={classes.customWidth}
                                labelId="root-label"
                                id="root-select"
                                value={value}
                                onChange={(e)=>{
                                  onChange(e);
                                  businessRuleForRoot(e.target.value);
                                }}
                                variant="outlined"
                                disabled={disableAccountInfo || disableRootOrg}
                              >
                                <MenuItem value="TMNA">TMNA</MenuItem>
                                <MenuItem value="D&IS">D&IS</MenuItem>
                                <MenuItem value="CT">CT</MenuItem>
                                <MenuItem value="TMF">TMF</MenuItem>
                              </Select>
                                <FormHelperText
                                  children={error?.message ?? null}
                                />
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} data-testid="rootEmailId">
                        <Controller
                          control={control}
                          name="rootEmailId"
                          rules={{
                            required: (validationForEmailOnRoot && validationForEmailOnCSP) ? "Root Email ID is required" : false,
                            maxLength: {
                              value: 100,
                              message: '(min 1, max 100)',
                            },
                            pattern: (validationForEmailOnRoot && validationForEmailOnCSP) ? {
                              value: /\S+@\S+\.\S+/,
                              message: 'Invalid email format'
                            }:{
                              value:/(?:)/, 
                              message:''},
                          }}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }: any) => (
                            <TextField
                              label={
                                <span>
                                  Root Email ID {(validationForEmailOnRoot && validationForEmailOnCSP) ? <span style={{ color: 'red' }}>*</span> : ""}
                                </span>
                              }
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message}
                              disabled={disableAccountInfo}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} data-testid="mfa">
                        <InputLabel shrink id="mfa-label">
                          MFA Enabled <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Controller
                          control={control}
                          name="mfaEnabled"
                          rules={{
                            required: 'MFA Enabled is required',
                          }}
                          render={({ field: { value, onChange },fieldState: { error } }: any) => (
                            <FormControl fullWidth  error={!!error}>
                            <Select
                              fullWidth
                              className={classes.customWidth}
                              labelId="mfa-label"
                              id="mfa-select"
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              disabled={disableAccountInfo}
                            >
                              <MenuItem value="N/A">N/A</MenuItem>
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                                <FormHelperText
                                  children={error?.message ?? null}
                                />
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <NonEditableTextField control={control} name='created' label='Creation Date'/>
                      <NonEditableTextField control={control} name='created_by' label='Created By'/>
                      <NonEditableTextField control={control} name='modified' label='Last Modified Date'/>

                    </Grid>
                  </Grid>
                  <div
                    className={classes.header}
                    style={{ display: 'flex', marginTop: '8px' }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '2.5',
                      }}
                    >
                      Contact Details
                    </span>
                  </div>
                  <Grid item id="ContactDetails">
                    <Divider orientation="horizontal" />
                  </Grid>

                  <Grid item xs={12} className={classes.rowMargin}>
                    <Grid container>
                      <Grid item xs={3}>
                        <ContactController
                          className={classes.customWidth}
                          control={control}
                          name="finSponsor"
                          options={contactsData?.value ?? []}
                          disabled={disableContactDetails}
                          onAutoCompChange={onAutoCompChange}
                          handleContacts={handleContacts}
                          placeholder="Search Financial Sponsor"
                          label="Financial Sponsors"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ContactController
                          className={classes.customWidth}
                          control={control}
                          name="accOwner"
                          options={contactsData?.value ?? []}
                          disabled={disableContactDetails}
                          onAutoCompChange={onAutoCompChange}
                          handleContacts={handleContacts}
                          placeholder="Search Account Owner"
                          label="Account Owners"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ContactController
                          className={classes.customWidth}
                          control={control}
                          name="techContact"
                          options={contactsData?.value ?? []}
                          disabled={disableContactDetails}
                          onAutoCompChange={onAutoCompChange}
                          handleContacts={handleContacts}
                          placeholder="Search Technical Contact"
                          label="Technical Contacts"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.header}>
                    {' '}
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '2.5',
                        marginTop: '8px',
                      }}
                    >
                      Application Details
                    </span>{' '}
                  </div>
                  <Grid item id="ApplicationDetails">
                    <Divider orientation="horizontal" />
                  </Grid>
                  <Grid item xs={12} className={classes.rowMargin}>
                    <Grid container>
                      <Grid item xs={4} data-testid="bul2">
                        <InputLabel shrink id="bul2-label">
                        Portfolio <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Controller
                          control={control}
                          name="businessUnitsL2"
                          rules={{
                            required: 'Portfolio is required',
                          }}
                          render={({ field: { value },fieldState: { error }  }: any) => (
                            <FormControl fullWidth  error={!!error}>
                            <Select
                              fullWidth
                              className={classes.customWidth}
                              labelId="bul2-label"
                              id="bul2-select"
                              value={value}
                              onChange={e => handleBUL2Change(e.target.value)}
                              variant="outlined"
                              disabled={disableApplicationDetails}
                            >
                              {l2Values && l2Values.length > 0
                                ? l2Values.map((l2: any) => (
                                    <MenuItem key={l2} value={l2}>
                                      {l2}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                              <FormHelperText
                                children={error?.message ?? null}
                              />
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} data-testid="bul3">
                        <InputLabel shrink id="bul3-label">
                          Program <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Controller
                          control={control}
                          name="businessUnitsL3"
                          rules={{
                            required: 'Program is required',
                          }}
                          render={({ field: { value, onChange },fieldState: { error }}: any) => (
                            <FormControl fullWidth  error={!!error}>
                            <Select
                              fullWidth
                              className={classes.customWidth}
                              labelId="bul3-label"
                              id="bul3-select"
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              disabled={disableApplicationDetails}
                            >
                              {l3Values && l3Values.length > 0
                                ? l3Values.map((l3: any) => (
                                    <MenuItem key={l3} value={l3}>
                                      {l3}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                            <FormHelperText
                                children={error?.message ?? null}
                              />
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} data-testid="appServiceId">
                        <Controller
                          control={control}
                          name="appServiceId"
                          rules={{
                            ...(isAppIdRequired?{required: 'Business App ID (Toyota Sys ID) is required'}:{required:false}),
                            maxLength: {
                              value: 50,
                              message: 'max length exceeded',
                            },
                          }}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }: any) => (
                            <TextField
                              label={
                                <span>
                                  Business App ID (Toyota Sys ID) <span style={{ color: 'red' }}>{isAppIdRequired?'*':''}</span>
                                </span>
                              }
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message ?? '(max length 50)'}
                              disabled={disableApplicationDetails}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.header}>
                    {' '}
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '2.5',
                        marginTop: '8px',
                      }}
                    >
                      Financial Information
                    </span>{' '}
                  </div>
                  <Grid item id="FinancialInformation">
                    <Divider orientation="horizontal" />
                  </Grid>
                  <Grid item xs={12} className={classes.rowMargin}>
                    <Grid container>
                      <Grid item xs={4} data-testid="compCode">
                        <Controller
                          control={control}
                          name="compCode"
                          rules={{
                            required: 'Company Code is required',
                            maxLength: {
                              value: 20,
                              message: '(max length 20)',
                            },
                            minLength: {
                              value: 1,
                              message: '(min 1, max 20)',
                            },
                          }}
                          render={({ field: { value, onChange },fieldState: { error } }: any) => (
                            <TextField
                              label={
                                <span>
                                  Company Code <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message ?? '(max length 20)'}
                              disabled={disableFinancialInfo}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid="genLedId">
                        <Controller
                          control={control}
                          name="genLedId"
                          rules={{
                            required: 'General Ledger ID is required',
                            pattern: {
                              value: /^(5\d*|6172320004)$/,
                              message: 'GLID must begin with 5 or be 6172320004',
                            },
                          }}
                          render={({ field: { value, onChange },fieldState: { error } }: any) => (
                            <TextField
                              label={
                                <span>
                                  General Ledger ID <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message ?? null}
                              disabled={disableGLID}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid="costCenter">
                        <Controller
                          control={control}
                          name="costCenter"
                          rules={{
                            required: 'Cost center is required',
                            maxLength: {
                              value: 60,
                              message: '(max length 60)',
                            },
                            minLength: {
                              value: 1,
                              message: '(min 1, max 60)',
                            },
                          }}
                          render={({ field: { value, onChange },fieldState: { error }  }: any) => (
                            <TextField
                              label={
                                <span>
                                  Cost Center  <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message ?? '(max length 60)'}
                              disabled={disableFinancialInfo}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} data-testid="wbsId">
                        <Controller
                          control={control}
                          name="wbsId"
                          rules={{
                            maxLength: {
                              value: 30,
                              message: '(min 1, max 30)',
                            },
                            minLength: {
                              value: 1,
                              message: '(min 1, max 30)',
                            },
                          }}
                          render={({ field: { value, onChange }  }: any) => (
                            <TextField
                              label={
                                <span>
                                  WBS ID  <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              //helperText={<>(WBS ID is required. A default value of 000001 will be retained)</>}
                              disabled={disableFinancialInfo}
                            />  
                          )}
                        />
                        <p style={{fontStyle:'12px'}}>(WBS ID is required. A default value of 000001 will be retained)</p>
                      </Grid>
                      <Grid item xs={4} data-testid="mse">
                        <Controller
                          control={control}
                          name="mse"
                          rules={{
                            required: 'Monthly Spend Estimate is required',
                            pattern: {
                              value: /^\d+$/,
                              message: 'Please enter an integer value',
                            },
                            max: {
                              value: 999999,
                              message: '(min 1, max 999,999)',
                            },
                            min: { value: 1, message: '(min 1, max 999,999)' },
                          }}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }: any) => (
                            <TextField
                              label={
                                <span>
                                  Monthly Spend Estimate ($) <span style={{ color: 'red' }}>*</span>
                                </span>
                              }
                              value={value}
                              onChange={event =>
                                onMonthlySpendChange(event, onChange, value)
                              }
                              variant="outlined"
                              error={!!error}
                              helperText= {error?.message ?? '(min 1, max 999,999)'}
                              disabled={disableFinancialInfo}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.header}>
                    {' '}
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '2.5',
                        marginTop: '8px',
                      }}
                    >
                      Notes
                    </span>{' '}
                  </div>
                  <Grid item id="NotesInformation">
                    <Divider orientation="horizontal" />
                  </Grid>
                  <Grid item xs={12} className={classes.rowMargin}>
                    <Grid container>
                      <Grid item xs={11} data-testid="notes">
                        <Controller
                          control={control}
                          name="notes"
                          render={({ field: { value, onChange } }: any) => (
                            <TextField
                              fullWidth
                              multiline
                              minRows={2}
                              label="Note/Comment"
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              maxRows={4}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
          </Content>
        </div>
      </form>
    </PageLayout>
  );
};
